#app {
  margin-top: 0px;
}
.nav-wrapper {
  margin-left: 20px;
}
.card-header.active {
  border: 0.1px solid #1e90ff; /* Slight green border */
  border-radius: 8px; /* Optional: smooth corners */
  box-shadow: 0 0 2px #1e90ff, 0 0 20px #1e90ff; /* Glowing effect */
  transition: box-shadow 0.3s ease-in-out; /* Smooth transition */
}
.icon-glow-green {
  color: #32cd32;
  /* -webkit-filter: drop-shadow( 0.10px 0.10px 0.10px #32cd32);
  filter: drop-shadow( 0.10px 0.10px 0.10px #32cd32);  */  
}

.icon-glow-blue {
  color: #1e90ff;
}
.icon-glow-red {
  color: red;
  
}
.glow-red {
  border: 2px solid red;
  box-shadow: 0 0 5px red;
  transition: box-shadow 0.3s ease, border-color 0.3s ease;
}

.circle {
  width: 32px;
  animation: rotate 30s linear infinite;
}

@keyframes rotate {
  from {
      transform: rotate(0deg);
  }
  to {
      transform: rotate(360deg);
  }
}
.progressModal {
  position: absolute;
  display: none;
  width: 100%;
  height: 100%;
  background-color: white;

  opacity: 0.6;
  z-index: 1000;
}
.hover-cell {
  cursor: pointer;
  transition: color 0.3s ease;
}

.red-text {
  color: red;
}
.hover-cell:hover {
  font-weight: bold; /* Just an example to make hover effect more noticeable */
}

.right {
  float: right;
  margin: 5px;
  scale: 1.25;
}
.progressModal > div {
  position: absolute;
  left: 50%;
  top: 50%;
  -moz-transform: translate(-50%, -50%);
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  -o-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}
input {
  color: black;
}
.card .card-content {
  padding: 0px 15px 6px 15px;
  line-height: 25px;
}

header,
main,
footer {
  /* padding-left: 300px; */
}
/* main > div {
  padding-left: 20px;
  padding-right: 20px;
} */

@media only screen and (max-width: 992px) {
  header,
  main,
  footer {
    padding-left: 0;
  }
}

.row {
  margin-bottom: 0px !important;
}

.btn.compact {
  height: 24px !important;
  line-height: 24px !important;
  padding: 0 9px !important;
}

p,
div.col.compact {
  padding: 0 2px !important;
  margin: 0 !important;
}
div.col.compact > input {
  height: 1.5rem !important;
}
.card-padded,
.padded {
  padding: 5px !important;
}

#historyDiv,
#customerNotesDiv {
  display: none;
}

#customerNotesApp > div.card-padded {
  padding-bottom: 10px !important;
}

.rbt.form-control large {
  width: 400px;
}
.rbt.form-control {
  position: relative;
  /* margin: 20px 0 40px; */
  /* width: 190px; */
}
.rbt-close-content {
  display: none;
}

.rbt-input-main.rbt.form-control.rbt-input:hover {
  box-shadow: 0px 0px 1px rgba(0, 0, 0, 0.1);
}
.rbt.form-control input {
  background-color: transparent;
  border: 0;
  border-bottom: 2px #fff solid;
  display: block;
  width: 100%;
  padding: 15px 0;
  font-size: 18px;
  /* color: #0d1117; */
}
.rbt.form-control input:focus {
  box-shadow: 0px 0px 1px rgba(0, 0, 0, 0.1);
}

.rbt.form-control input:focus,
.rbt.form-control input:valid {
  outline: 0;
  border-bottom-color: #a6ce39;
}
.rdt.form-control {
  /* border: none; */
  /* border-radius: 0%; */
}
.rbt.form-control label {
  position: absolute;
  top: 15px;
  left: 0;
  pointer-events: none;
}

.rbt.form-control label span {
  display: inline-block;
  font-size: 18px;
  min-width: 5px;
  color: #fff;
  transition: 0.3s cubic-bezier(0.68, -0.55, 0.265, 1.55);
}

.rbt.form-control input:focus + label span,
.rbt.form-control input:valid + label span {
  color: #a6ce39;
  transform: translateY(-30px);
}
.clickable:hover {
  cursor: pointer;
  opacity: 0.6;
}

#oldLogsSidebar {
  position: fixed;
}

#logsDiv {
  margin-top: 20px;
  /* float: left; */
}

#navbarNav {
  /* float: right; */
}
html {
  min-height: 100%;
}
html.dark {
  background: #0d1117;
}
html.dark .card,
html.dark #viewLogModal,
html.dark #logTimeEntry,
html.dark #settingsModal {
  background: #161b22;
  color: gray;
  border: 1px solid gray;
}
html.dark #historyDiv,
html.dark #customerNotesDiv {
  color: gray;
}
html.dark .modal-footer,
html.dark .modal-header {
  background: #161b22 !important;
  color: gray;
}
.modal-header {
  padding: 1px;
}
html.dark .progressModal {
  background-color: #161b22;
}
html.dark input,
html.dark textarea,
html.dark p {
  color: gray;
}
html.dark h3 {
  color: white;
}
html.dark .autocomplete-content {
  background: #161b22;
}
html.dark .autocomplete-content li .highlight {
  color: gray;
}
html.dark .sidenav {
  background: black;
  color: gray;
}
html.dark span {
  color: gray;
}

.hlabel {
  transform: none !important;
  left: 10px !important;
  font-size: 2.1rem;
  position: absolute;
}
